<template>
  <div>
    <highcharts class="chart" :options="chart"></highcharts>
  </div>
</template>

<script>
export default {
  props: {
    productId: {
      type: String,
      required: true
    },
    weeksToShow: {
      type: Number,
      default: 26
    }
  },
  computed: {
    salesStatistics() {
      const product = this.$store.state.products.find(p => p.id === this.productId)
      if (product && product.salesByWeek) {
        const { salesByWeek } = product
        const salesWeekKeys = Object.keys(salesByWeek).sort()
        const salesStatistics = salesWeekKeys.map((weekLabel, weekIndex) => {
          const amount = salesByWeek[weekLabel].sum
          const relevantWeeks = [weekLabel]
          for (let i = weekIndex > 3 ? 3 : weekIndex; i > 0; i--) {
            relevantWeeks.push(salesWeekKeys[weekIndex - i])
          }
          let averageSales = 0
          relevantWeeks.forEach(week => {
            averageSales += salesByWeek[week].sum
          })
          averageSales = parseFloat((averageSales / relevantWeeks.length).toFixed(1))
          return {
            weekLabel,
            amount,
            averageSales,
            relevantWeeks
          }
        })
        return salesStatistics
      } else {
        return null
      }
    },
    chart() {
      const chart = {
        plotOptions: {
          series: {
            animation: false
          }
        },
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          tickInterval: 1,
          title: {
            enabled: false
          }
        },
        legend: {
          enabled: false
        },
        colors: ['#6fcd98', '#ff0000'],
        series: [
          {
            data: [],
            name: 'Sales',
            type: 'column'
          },
          {
            data: [],
            name: '4W Average',
            type: 'spline'
          }
        ],
        tooltip: {
          shared: true
        },
        credits: {
          enabled: false
        }
      }

      this.salesStatistics.slice(-this.weeksToShow).forEach(week => {
        const { weekLabel, amount, averageSales } = week
        chart.series[0].data.push(amount)
        chart.series[1].data.push(averageSales)
        chart.xAxis.categories.push(`${weekLabel.substr(0, 4)} W${weekLabel.substr(5, 2)}`)
      })

      return chart
    }
  }
}
</script>
