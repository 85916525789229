<template>
  <div>
    <form class="product-form container" @submit.prevent="saveProduct">
      <h2 class="h2 mt-4 mb-3">Product Info</h2>
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div class="form-row">
          <div class="form-group mr-2">
            <label for="brandName">Brand Name</label>
            <input
              type="text"
              class="form-control"
              id="brandName"
              v-model.trim="product.brandName"
              v-autowidth="{ minWidth: '5rem', comfortZone: 0 }"
              style="font-size: 1.5rem;"
            />
          </div>
          <div class="form-group mr-2">
            <label for="label">Label</label>
            <input
              type="text"
              class="form-control"
              id="label"
              v-model.trim="product.label"
              v-autowidth="{ minWidth: '5rem', comfortZone: 0 }"
              style="font-size: 1.5rem;"
              required
            />
          </div>
          <div class="form-group">
            <label for="additionalLabel">Additional Label</label>
            <input
              type="text"
              class="form-control"
              id="additionalLabel"
              v-model.trim="product.additionalLabel"
              v-autowidth="{ minWidth: '7rem', comfortZone: 0 }"
              style="font-size: 1.5rem;"
            />
          </div>
        </div>
        <div>
          <div class="custom-control-inline custom-switch">
            <input type="checkbox" class="custom-control-input" id="live" v-model="product.live" />
            <label class="custom-control-label" for="live">Live</label>
          </div>
          <div class="custom-control-inline custom-switch">
            <input
              type="checkbox"
              class="custom-control-input success"
              id="temporarilyUnavailable"
              v-model="product.temporarilyUnavailable"
            />
            <label class="custom-control-label" for="temporarilyUnavailable">Temporarily Unavailable</label>
          </div>
        </div>
      </div>
      <div class="container p-0">
        <div class="row no-gutters">
          <div class="col-8">
            <div class="form-row">
              <label for="description">Description</label>
              <textarea-autosize v-model="product.description" class="form-control mb-3" id="description" rows="1" />
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="categoryId">Category</label>
                <select class="custom-select" id="categoryId" v-model="product.categoryId" required>
                  <option v-for="category in categories" :key="category.id" :value="category.id">
                    {{ category.label }} ({{ category.id }})
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="type">Type</label>
                <select class="custom-select" id="type" v-model="product.type" required>
                  <option v-for="productType in productTypes" :key="productType" :value="productType">
                    {{
                      productType
                        .toLowerCase()
                        .split('-')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                    }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="gender">Gender</label>
                <select class="custom-select" id="gender" v-model="product.gender" required>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="unisex">Unisex</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-4 pl-2 d-flex justify-content-center align-items-center">
            <ProductImageEditor
              :imageId="product.img"
              @reset-image="product.img = null"
              @image-uploaded="imageId => $set(product, 'img', imageId)"
            />
          </div>
        </div>
      </div>
      <h2 class="h2 mb-3">Size</h2>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="packageSize">Package Size</label>
          <input type="text" class="form-control" id="packageSize" v-model="product.packageSize" required />
        </div>
        <div class="form-group col-md-3">
          <label for="brandName">Package Unit</label>
          <select class="custom-select" id="packageUnit" v-model="product.packageUnit" required>
            <option v-for="packageUnit in packageUnits" :key="packageUnit">{{ packageUnit }}</option>
          </select>
        </div>
        <div class="form-group col-md-3" v-if="product.type === 'tobacco'">
          <label for="packsPerCarton">Packs per Carton</label>
          <input type="number" class="form-control" id="packsPerCarton" v-model.number="product.packsPerCarton" />
        </div>
      </div>

      <h2 class="h2 mt-4 mb-3">Pricing</h2>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="taxRate">Tax Rate</label>
          <select class="custom-select" id="taxRate" v-model="product.taxRate" required>
            <option :value="7">7 %</option>
            <option :value="19">19 %</option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="EKNet">Buying Price (Net)</label>
          <div class="input-group">
            <input type="number" class="form-control" id="EKNet" v-model.number="product.EKNet" step="0.001" required />
            <div class="input-group-append">
              <span class="input-group-text">€</span>
            </div>
          </div>
          <small id="emailHelp" class="form-text text-muted ml-2" v-if="product.EKNet && product.taxRate">
            Gross {{ (product.EKNet * (1 + product.taxRate / 100)).toFixed(2).replace('.', ',') }} €
          </small>
        </div>
        <div class="form-group col-md-2">
          <label for="referencePrice">Reference Price (Gross)</label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              id="referencePrice"
              v-model.number="product.referencePrice"
              step="0.01"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">€</span>
            </div>
          </div>
          <small id="emailHelp" class="form-text text-muted ml-2" v-if="referencePriceNet">
            Net {{ referencePriceNet.toFixed(2).replace('.', ',') }} €
          </small>
        </div>
        <div class="form-group col-md-3">
          <label for="retailPrice">Retail Price (Gross)</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-primary" type="button" @click="calculateRetailPrice">Calc</button>
            </div>
            <input
              type="number"
              class="form-control"
              id="retailPrice"
              v-model.number="product.retailPrice"
              step="0.01"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">€</span>
            </div>
          </div>
          <small id="emailHelp" class="form-text text-muted ml-2" v-if="retailPriceNet">
            Net {{ retailPriceNet.toFixed(2).replace('.', ',') }} €
          </small>
        </div>
        <div class="form-group col-md-3" style="margin-top: 36px" v-if="margin">
          = {{ margin.sum.toFixed(2).replace('.', ',') }} € margin ({{ margin.percent.replace('.', ',') }} %)
        </div>
      </div>

      <div v-if="['alcohol', 'non-alcohol'].includes(product.type)" class="form-row">
        <div class="form-group col-md-3">
          <label for="containerDeposit">Container deposit</label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              id="containerDeposit"
              v-model.number="product.containerDeposit"
              step="0.01"
            />
            <div class="input-group-append">
              <span class="input-group-text">€</span>
            </div>
          </div>
        </div>
      </div>
      <h2 class="h2 mt-4 mb-3">Stock Options</h2>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="source">Source</label>
          <select class="custom-select" id="source" v-model="product.source" required>
            <option v-for="source in sources" :key="source.id" :value="source.id">
              {{ source.label }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="minStock">Minimum Stock</label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              id="minStock"
              v-model.number="product.minStock"
              step="1"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">Units</span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-2">
          <label for="rebuyAmount">Rebuy Amount</label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              id="rebuyAmount"
              v-model.number="product.rebuyAmount"
              step="1"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">Units</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="h2 mt-4 mb-3">Offers</h2>
          <!-- <button type="button" class="btn btn-sm btn-outline-success">Add</button> -->
        </div>
        <ul class="list-group">
          <li
            v-for="(offer, i) in product.offers"
            :key="i"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
          >
            {{ offer.source }}
            <div>
              <span v-if="offer.tags && offer.tags.includes('exWorks')" class="badge badge-info badge-pill mr-2">
                Ex Works
              </span>
              <span v-if="offer.tags && offer.tags.includes('carriageFree')" class="badge badge-info badge-pill mr-2">
                Carriage Free
              </span>
              <span v-if="offer.minAmount" class="badge badge-secondary badge-pill mr-2">
                Min: {{ offer.minAmount }}
              </span>
              <span class="badge badge-primary badge-pill">{{ offer.offer.toString().replace('.', ',') }} €</span>
            </div>
          </li>
          <li v-if="!product.offers || product.offers.length === 0" class="list-group-item list-group-item-light">
            There are no offers in the system
          </li>
        </ul>
      </div>

      <div>
        <h2 class="h2 mt-4 mb-3">Product Link</h2>
        <ul class="list-group">
          <li class="list-group-item list-group-item-light">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <img
                  src="@/assets/icons/rossmann.png"
                  v-if="product.productLink && product.productLink.source === 'rossmann'"
                  width="24px"
                  class="mr-3"
                />
                <img
                  src="@/assets/icons/tabakstore.png"
                  v-if="product.productLink && product.productLink.source === 'tabakstore'"
                  width="24px"
                  class="mr-3"
                />
                <div class="d-flex flex-column" v-if="product.productLink">
                  <a
                    :href="product.productLink.url"
                    :class="{ 'text-danger': product.productLink.valid === false }"
                    target="_blank"
                    >{{ product.productLink.source }}</a
                  >
                  <small>
                    <span v-if="product.productLink.valid === false" class="mr-2">
                      ⚠️ Invalid Link
                    </span>
                    Last Lookup:
                    {{
                      product.productLink.lastLookup !== null
                        ? formatDate(new Date(product.productLink.lastLookup.seconds * 1000), true)
                        : 'Never'
                    }}</small
                  >
                </div>
                <div v-else>No Link Set</div>
              </div>
              <div v-if="product.priceLookup" class="text-center text-dark">
                <div>
                  <span :style="product.priceLookup.reducedPrice ? 'text-decoration: line-through;' : ''">{{
                    formatCurrency(product.priceLookup.regularPrice)
                  }}</span>
                  <span class="ml-2" v-if="product.priceLookup.reducedPrice">{{
                    formatCurrency(product.priceLookup.reducedPrice)
                  }}</span>
                </div>
                <small>
                  {{
                    product.priceLookup.lastLookup !== null
                      ? formatDate(new Date(product.priceLookup.lastLookup.seconds * 1000), true)
                      : 'Never'
                  }}</small
                >
              </div>
              <div>
                <DotMenu
                  :handleText="product.productLink ? 'Change' : 'Set'"
                  class="btn btn-sm btn-outline-secondary ml-2"
                >
                  <li @click="setProductLink('rossmann')">Rossmann</li>
                  <li @click="setProductLink('tabakstore')">tabakstore.de</li>
                </DotMenu>
                <button
                  class="btn btn-sm btn-outline-danger ml-2"
                  @click="removeProductLink"
                  v-if="product.productLink"
                  type="button"
                >
                  Remove
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="d-flex justify-content-end mt-5 mb-5">
        <button type="submit" class="btn btn-success btn-lg">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import ProductImageEditor from '@/components/ProductImageEditor'
import { db } from '@/shared/firebase'
import catalogJSON from '@/data/catalog.json'
import DotMenu from '@/shared/DotMenu'

export default {
  props: {
    productId: {
      type: String,
      required: true
    }
  },
  components: { ProductImageEditor, DotMenu },
  data() {
    return {
      product: {
        live: true
      }
    }
  },
  created() {
    const product = this.$store.state.products.find(p => p.id === this.productId)
    if (typeof product !== 'undefined') this.product = JSON.parse(JSON.stringify(product))
  },
  computed: {
    categories() {
      const categories = []
      const catalog = catalogJSON
      const parseCategories = catalog => {
        Object.keys(catalog.categories).forEach(categoryId => {
          const category = catalog.categories[categoryId]
          categories.push({
            label: category.label,
            id: categoryId
          })
          if (category.categories) parseCategories({ categories: category.categories })
        })
      }
      parseCategories(catalog)
      return _.orderBy(categories, ['id'])
    },
    sources() {
      const sources = this.$store.state.purchase.sources
      return _.orderBy(
        Object.keys(sources).map(id => {
          return { ...sources[id], id }
        }),
        [source => source.label.toLowerCase()]
      )
    },
    packageUnits() {
      return ['g', 'ml', 'l', 'Stück', 'Rollen', 'Blatt', 'Heft']
    },
    productTypes() {
      return ['nonfood', 'food', 'alcohol', 'non-alcohol', 'magazine', 'stationery', 'tobacco']
    },
    referencePriceNet() {
      const { referencePrice, taxRate } = this.product
      if (!referencePrice || !taxRate) return null
      return referencePrice / (1 + taxRate / 100)
    },
    retailPriceNet() {
      const { retailPrice, taxRate } = this.product
      if (!retailPrice || !taxRate) return null
      return retailPrice / (1 + taxRate / 100)
    },
    margin() {
      const { EKNet } = this.product
      const { retailPriceNet } = this
      if (!EKNet || !retailPriceNet) return null
      return {
        sum: retailPriceNet - EKNet,
        percent: ((1 - EKNet / retailPriceNet) * 100).toFixed(1)
      }
    }
  },
  methods: {
    calculateRetailPrice() {
      const { referencePrice } = this.product
      if (referencePrice) {
        const retailPrice = parseFloat((referencePrice * 1.3).toFixed(2).substr(0, 3) + 9)
        this.$set(this.product, 'retailPrice', retailPrice)
      }
    },
    setProductLink(source) {
      const url = prompt('Enter URL')
      if (url) {
        this.$set(this.product, 'productLink', {
          valid: null,
          lastLookup: null,
          url,
          source
        })
      }
    },
    removeProductLink() {
      this.$delete(this.product, 'productLink')
    },
    saveProduct() {
      const product = this.product
      if (typeof product.img !== 'string') return alert('Please upload a product image')
      if (isNaN(product.packsPerCarton) || product.packsPerCarton < 1) delete product.packsPerCarton
      if (!product.brandName) product.brandName = ''
      if (product.containerDeposit === '') delete product.containerDeposit
      if (product.containerDeposit && product.taxRate === 7) {
        return alert('Container Deposit is only implemented for products with a tax rate of 19 %.')
      }
      db.doc(`/private/catalog/products/${this.productId}`)
        .set({ ...product })
        .then(() => {})
        .catch(err => {
          alert(err.message)
        })
      this.$emit('saved')
    }
  }
}
</script>

<style lang="scss" scoped>
.product-form {
  input,
  textarea,
  select {
    color: #000;
  }
}
</style>
