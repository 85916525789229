<template>
  <div>
    <h2 class="ml-5 mb-4">Kiosk Listings</h2>
    <div v-if="listings === null">Loading</div>
    <div v-else>
      <ul v-if="listings.length !== 0" class="list-group m-4">
        <li
          v-for="(listing, i) in listings"
          :key="i"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          {{ listing.organizationName }}
          <div>
            <span class="badge badge-primary badge-pill">{{ listing.inStock }} / {{ listing.inventorySpace }}</span>
            <button class="btn badge badge-pill badge-warning ml-2" @click="deleteListing(listing)">Del</button>
          </div>
        </li>
      </ul>
      <ul v-else class="list-group m-4">
        <li class="list-group-item">
          No Listings Found
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'

export default {
  props: {
    productId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      listings: null,
      subscription: null
    }
  },
  async created() {
    this.subscription = db
      .collectionGroup('kioskproducts')
      .where('productId', '==', this.productId)
      .onSnapshot(snapshot => {
        this.listings = snapshot.docs.map(doc => {
          const organizationId = doc.ref.path.split('/')[1]
          const organization = this.$store.state.organizations.find(o => o.id === organizationId)
          const organizationName = organization ? organization.name : 'Unknown Organization'
          let inStock = 0
          doc.data().inventory.forEach(i => (inStock += i.amount))
          return { ...doc.data(), id: doc.id, organizationId, organizationName, inStock }
        })
      })
  },
  beforeDestroy() {
    this.subscription()
  },
  methods: {
    deleteListing(listing) {
      const confirmed = confirm(`Are you sure to delete to kioskproduct for ${listing.organizationName}?`)
      if (confirmed) {
        db.doc(`organizations/${listing.organizationId}/kioskproducts/${listing.id}`).delete()
      }
    }
  }
}
</script>
