<template>
  <div v-if="imageId" class="d-flex flex-column align-items-center">
    <div class="d-flex align-items-end">
      <a
        v-for="size in [256, 1200]"
        :href="
          `https://storage.googleapis.com/${
            env === 'production' ? 'emma-static' : 'emma-static-dev'
          }/products/${imageId}_${size}.jpg`
        "
        :key="size"
        target="_blank"
        class="d-flex flex-column align-items-center"
      >
        <img
          :src="
            `https://storage.googleapis.com/${
              env === 'production' ? 'emma-static' : 'emma-static-dev'
            }/products/${imageId}_${size}.jpg`
          "
          :style="`width: ${size === 256 ? 64 : 112}px; border: 1px #ccc solid;`"
          class="m-1"
        />
        <span>{{ size }}</span>
      </a>
    </div>
    <button type="button" class="btn btn-outline-danger mt-2" @click="$emit('reset-image')">Remove</button>
  </div>
  <div v-else>
    <div v-if="!uploadInProgress && !resizingInProgress">
      <input
        @click="$event.target.value = null"
        @change="uploadImage($event.target.files[0])"
        type="file"
        class="custom-file-input w-0 d-none"
        id="customFile"
      />
      <label class="btn btn-outline-primary" for="customFile">📸 Select Image</label>
    </div>
    <div v-else>
      <span v-if="uploadInProgress">
        Uploading... <span v-if="uploadProgress !== 0">{{ uploadProgress }} %</span>
      </span>
      <span v-if="resizingInProgress">Resizing Images...</span>
    </div>
  </div>
</template>

<script>
import { fb, functions, env } from '@/shared/firebase'
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    imageId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      uploadInProgress: false,
      uploadProgress: 0,
      resizingInProgress: false,
      env
    }
  },
  methods: {
    resetUploader() {
      this.$refs.fileInput.value = null
    },
    uploadImage(file) {
      const fileName = uuidv4().substr(0, 8)
      const fileExtension = file.name.split('.').pop()
      const filePath = `products_originals/${fileName}.${fileExtension}`
      const bucket = env === 'production' ? 'emma-static' : 'emma-static-dev'
      this.uploadInProgress = true
      const storageRef = fb
        .app()
        .storage(bucket)
        .ref(filePath)

      const uploadTask = storageRef.put(file)

      uploadTask.on(
        'state_changed',
        snapshot => {
          this.uploadProgress = parseFloat(((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(1))
        },
        error => {
          this.uploadInProgress = false
          alert(error.message)
        },
        () => {
          this.uploadInProgress = false
          this.uploadProgress = 0
          this.resizeImage(fileName, fileExtension)
        }
      )
    },

    resizeImage(fileName, fileExtension) {
      this.resizingInProgress = true
      const resizeProductImage = functions.httpsCallable('resizeProductImage')
      resizeProductImage({ fileName, fileExtension })
        .then(() => {
          this.resizingInProgress = false
          this.$emit('image-uploaded', fileName)
        })
        .catch(err => {
          this.resizingInProgress = false
          alert(err.message)
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
